import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Tag from '../../Tags/Tag';
import maib from '../../../assets/global/maib.png';
import { CursesSections } from '../../../types/common';
import { Button } from '../../../components/UI/Button';
import { CoursesModifications } from '../../../types/courses/courses';
import { COLORS } from '../../../constants/colors';

import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

import {
   OneStepWrapper,
   InfoHolder,
   BottomRow,
   FirstStepHolderForm,
   FormTitle,
   ButtonTextHolder,
   LeftSideInfo,
   RightSideInfo,
} from '../styled';
import { getTransactionId } from '../../../store/actions/userActions';
import { selectAllCourses, selectPremiumCourses } from '../../../store/selectors/courses';

type Props = {
   handleNextStep: () => void;
   modification?: CursesSections;
   course: CoursesModifications;
};

const FirstStep = ({ handleNextStep, modification, course }: Props) => {
   const [checked, setChecked] = useState(false);
   const { enqueueSnackbar } = useSnackbar();
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();
   const date = new Date();
   const premiumCourses = useSelector(selectPremiumCourses);
   const courses = useSelector(selectAllCourses);
   const paidCourse =
      premiumCourses.find((paidCourse) => paidCourse.courseModification === course) ||
      courses.find((paidCourse) => paidCourse.courseModification === course);

   const handleButtonClick = () => {
      dispatch(getTransactionId({ amount: paidCourse?.cost || 50000, handleNextStep, enqueueSnackbar }) as any);
   };

   return (
      <>
         <FirstStepHolderForm>
            <FormTitle isMobile={isMobile}>
               <p>Информация о покупке</p>

               <div className='powered'>
                  <div className='powered-text'>Powered by </div> <img src={maib} alt='' />
               </div>
            </FormTitle>
            <OneStepWrapper>
               <InfoHolder>
                  <LeftSideInfo isMobile={isMobile}>
                     <div>Представитель:</div>
                     <div>Сайт:</div>
                     <div>Сумма:</div>
                     <div>Дата:</div>
                     <div>Описание:</div>
                  </LeftSideInfo>
                  <RightSideInfo isMobile={isMobile}>
                     <div>ООО «Начало Жизни»</div>
                     <div>app.urbancenter.md</div>
                     <div>500 MDL</div>
                     <div>
                        {date.getDate()}.{date.getMonth()}.{date.getFullYear()}
                     </div>
                     <div>{t(`course.${course}.title`)}</div>
                  </RightSideInfo>
               </InfoHolder>
            </OneStepWrapper>
         </FirstStepHolderForm>
         <BottomRow isMobile={isMobile}>
            <div className='terms'>
               <Checkbox
                  color='primary'
                  onChange={(e) => setChecked(e.target.checked)}
                  checked={checked}
                  sx={{
                     color: COLORS.white,
                     '&.Mui-checked': {
                        color: COLORS.magenta,
                     },
                  }}
               />{' '}
               <span className='terms-text'>
                  {' '}
                  Согласен с{' '}
                  <Link className='link' to={'/urban_terms'}>
                     условиями.
                  </Link>{' '}
               </span>
            </div>
            <Button
               disabled={!checked}
               htmlType='submit'
               modifier='primaryOutlined'
               color={'Magenta' as CursesSections}
               onClick={handleButtonClick}
            >
               {' '}
               <ButtonTextHolder>
                  {' '}
                  <div className='button-text'>Купить</div>
                  <Tag name={modification as CursesSections} customName='500 MDL' />
               </ButtonTextHolder>
            </Button>
         </BottomRow>
      </>
   );
};

export default FirstStep;
