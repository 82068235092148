
import { Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Snackbar } from '@mui/material';

import HomePage from './HomePage';
import LoginPage from './LoginPage/LoginPage';
import AdminPage from './AdminPage/AdminPage';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import RegistrationPage from './RegistrationPage.tsx/RegistrationPage';

import { selectUserData, selectUserProfile } from '../../store/selectors/user';
import { COLORS } from '../../constants/colors';
import { activateUserEmail } from '../../store/actions/userActions';
import PasswordResetPage from './PasswordResetPage/PasswordResetPage';

const Pages = () => {
   const isAuthenticated = useSelector(selectUserData);
   const [open, setOpen] = useState(false);
   const dispatch = useDispatch()
   const { enqueueSnackbar } = useSnackbar()
   const userProfile = useSelector(selectUserProfile);

   const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
         return;
      }

      setOpen(false);
   };

   useEffect(() => {
      setTimeout(() => {
         setOpen(true)
      }, 2001)
   }, [userProfile])

   const successCallback = () => {
      enqueueSnackbar(`Письмо на почту ${userProfile?.email} успешно отправлено!`, { variant: 'success' })
   }

   const errorCallback = () => {
      enqueueSnackbar(`Ошибка при отправке, попробуйте еще раз`, { variant: 'error' })
   }


   const handleActivateEmail = () => {
      dispatch(activateUserEmail({ successCallback, errorCallback }) as any)
      setOpen(false);
   };

   const action = (
      <Fragment>
         <Button
            size="small"
            onClick={handleActivateEmail}
            style={{
               color: COLORS.magenta,
               maxWidth: 500
            }}
         >
            Отправить письмо
         </Button>
         <IconButton
            size="small"
            aria-label="close"
            style={{
               color: COLORS.magenta,
               maxWidth: 200
            }}
            onClick={handleClose}
         >
            <CloseIcon fontSize="small" />
         </IconButton>
      </Fragment>
   );

   const renderLongTextSnackbar = () => {
      return (
         <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            onClose={handleClose}
            message={<>
               Привет, {userProfile?.name}, активируй свой эмэйл, <b> {userProfile?.email}! </b> <br /> Для возможности смены пароля!
            </>}
            style={{
               maxWidth: 250
            }}
            key={"top"}
            action={action}
         />
      );
   }

   return (
      <div style={{
         background: COLORS.urbanBlack
      }}>
         <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/api/users/reset/:uidb64/:token" element={<PasswordResetPage />} />
            <Route path="/admin" element={<ProtectedRoute isAuthenticated={!!isAuthenticated}> <AdminPage /> </ProtectedRoute>} />
         </Routes>

         {/* We need this page to work around application */}
         <HomePage />
      </div>
   );
};

export default Pages;