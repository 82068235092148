import React from 'react';
import {
   NoUserProfileInfoWrapper,
   NoUserProfileInfSubtitle,
   NoUserProfileInfTitle,
   UserEmail,
   UserImage,
   UserName,
   UserPhotoWrapper,
   UserProfileInfoWrapper,
   UserPROTag,
} from './styled';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../../store/selectors/user';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/UI/Button';
import { useNavigate } from 'react-router-dom';
import { PROD_API } from '../../../constants/api';

import logo from '../../../assets/header/small-logo.svg';

export const UserProfileInfo = () => {
   const { t } = useTranslation();
   const navigate = useNavigate();
   const userProfile = useSelector(selectUserProfile);
   const isUserLoggedIn = !!userProfile?.id;

   if (!isUserLoggedIn) {
      return (
         <NoUserProfileInfoWrapper>
            <NoUserProfileInfTitle>{t('navigation.userProfile.noUserTitle')}</NoUserProfileInfTitle>
            <NoUserProfileInfSubtitle>{t('navigation.userProfile.noUserSubtitle')}</NoUserProfileInfSubtitle>
            <Button modifier='login' onClick={() => navigate('/login')}>
               {t('navigation.userProfile.enter')}
            </Button>
         </NoUserProfileInfoWrapper>
      );
   }

   return (
      <UserProfileInfoWrapper>
         <UserPhotoWrapper>
            <UserImage src={`${PROD_API}/${userProfile?.profile_image}`} alt='user' />
            {userProfile?.is_admin && <UserPROTag>
               <img src={logo} alt="" />
               PRO</UserPROTag>}

         </UserPhotoWrapper>
         <UserName>
            {userProfile?.name}
         </UserName>
         <UserEmail>
            {userProfile?.email}
         </UserEmail>
      </UserProfileInfoWrapper>
   );
};
