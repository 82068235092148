import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


import RecommendedCourses from './RecommendedCourses';
import { changeActiveTab, filterCourses, labelCourses } from '../../../store/actions/appActions';
import { selectAllCourses, selectFilter, selectPremiumCourses } from '../../../store/selectors/courses';
import Course from '../../Courses/Course/Course';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import { ActiveFilters } from '../../../store/reducers/types';
import Tag from '../../Tags/Tag';
import { selectUserProfile } from '../../../store/selectors/user';
import { CursesSections } from 'types/common';
import MaterialModal from '../../UI/Modal/MaterialModal';
import { COLORS } from '../../../constants/colors';
import { COURSES } from '../../../constants/courses';
import { CoursesModifications } from 'types/courses/courses';
import PaymentStepsCallback from '../../PaymentSteps/PaymentStepsCallback';

import {
   AllCoursesContainer,
   CoursesWrapper,
   OneCourseWrapper,
   TitleRowWrapper,
   TitlePremiumRowWrapper
} from './styled';
import { selectCurrentLanguage } from '../../../store/selectors/translation';
import { useTranslation } from 'react-i18next';
import CourseSearch from './CourseSearch/CourseSearch';

const AllCourses = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { t } = useTranslation();
   const allPremiumCourses = useSelector((selectPremiumCourses));
   const user = useSelector(selectUserProfile)
   const currentLanguage = useSelector(selectCurrentLanguage);
   const selectedLanguage = user?.language || currentLanguage;
   const userPremiumCourses = allPremiumCourses.filter(course => {
      return user?.premium_courses.includes(course.courseModification);
   }).filter(course => course.language.includes(selectedLanguage as string));
   const courses = useSelector((selectAllCourses)).filter(course => course.language.includes(selectedLanguage as string));
   const filter = useSelector((selectFilter));
   const { isMobile } = useMediaQuery();
   const [openPaymentModal, setOpenPaymentModal] = useState(false);
   const [searchValue, setSearchValue] = useState('');
   const [filteredCourses, setFilteredCourses] = useState(courses);

   // get params from url
   const location = useLocation()
   const params = new URLSearchParams(location.search)
   const isPaymentCallback = params.get('payment') === 'success';

   useEffect(() => {
      if (isPaymentCallback) {
         setOpenPaymentModal(true);
      }
   }, [])

   const handleCloseModal = () => {
      // redirect to cabinet
      navigate(`/courses`);
      setOpenPaymentModal(false);
   }

   const handleFilterCourse = (filter: ActiveFilters) => {
      dispatch(labelCourses({ label: 'none' }))
      dispatch(filterCourses({ filter: filter as ActiveFilters }))
   }

   useEffect(() => {
      dispatch(changeActiveTab({ activeTab: 'courses' }))
   }, [dispatch])

   useEffect(() => {
      if (searchValue) {
         setFilteredCourses(courses.filter(course => {
            return t(`course.${course.courseModification}.title`).includes(searchValue);
         }));
      } else {
         setFilteredCourses(courses);
      }
   }, [searchValue]);

   const filters = [
      {
         label: `${t('course.all')}`,
         onClick: () => handleFilterCourse('all' as ActiveFilters),
         active: filter === 'all'
      },
      {
         label: `${t('course.online')}`,
         onClick: () => handleFilterCourse('online' as ActiveFilters),
         active: filter === 'online'
      },
      {
         label: `${t('course.paid')}`,
         onClick: () => handleFilterCourse('paid' as ActiveFilters),
         active: filter === 'paid'
      },
      {
         label: `${t('course.audio')}`,
         onClick: () => handleFilterCourse('audio' as ActiveFilters),
         active: filter === 'audio'
      },
      {
         label: `${t('course.offline')}`,
         onClick: () => handleFilterCourse('offline' as ActiveFilters),
         active: filter === 'offline'
      },
      {
         label: `${t('course.book')}`,
         onClick: () => handleFilterCourse('book' as ActiveFilters),
         active: filter === 'book'
      },
   ]

   return (
      <AllCoursesContainer isMobile={isMobile}>
         {selectedLanguage !== 'ro' && <RecommendedCourses />}
         {user?.is_admin && allPremiumCourses.length !== 0 && <>
            <TitlePremiumRowWrapper isMobile={isMobile}>
               <h1>{t('course.premiumCourses')}</h1> <Tag customName="Admin" name={'Youth' as CursesSections} />
            </TitlePremiumRowWrapper>
            <CoursesWrapper isMobile={isMobile}>
               {allPremiumCourses.map((course) => {
                  return <OneCourseWrapper key={course.courseModification}>
                     <Course
                        rating={course.rating}
                        section={course.section}
                        button={course.button}
                        courseModification={course.courseModification}
                     />
                  </OneCourseWrapper>
               })}
            </CoursesWrapper>
         </>
         }
         {(!user?.is_admin && user?.premium_courses && user?.premium_courses.length > 0) && <>
            <TitlePremiumRowWrapper isMobile={isMobile}>
               <h1>{t('course.premiumCourses')}</h1> <Tag customName="По подписке" name={'Premium' as CursesSections} />
            </TitlePremiumRowWrapper>
            <CoursesWrapper isMobile={isMobile}>
               {userPremiumCourses.map((course) => {
                  return <OneCourseWrapper key={course.courseModification}>
                     <Course
                        rating={course.rating}
                        section={course.section}
                        button={course.button}
                        courseModification={course.courseModification}
                     />
                  </OneCourseWrapper>
               })}
            </CoursesWrapper>
         </>
         }
         <TitleRowWrapper isMobile={isMobile}>
            <h1>{t('course.allCourses')}</h1>
            <CourseSearch filters={filters} setSearchValue={setSearchValue} searchValue={searchValue} />
         </TitleRowWrapper>
         <CoursesWrapper isMobile={isMobile}>
            {filteredCourses.map((course) => {
               return <OneCourseWrapper key={course.courseModification}>
                  <Course
                     rating={course.rating}
                     section={course.section}
                     button={course.button}
                     courseModification={course.courseModification}
                  />
               </OneCourseWrapper>
            })}
         </CoursesWrapper>
         <MaterialModal
            open={openPaymentModal}
            handleClose={handleCloseModal}
            bgcolor={COLORS.courseCardBackground}
            width={isMobile ? 400 : undefined}
            height={isMobile ? 500 : undefined}
            modification={"NotSelected" as CursesSections}
            title='Оплата курса'
         >
            <PaymentStepsCallback
               modification={"NotSelected" as CursesSections}
               course={COURSES.restoreBroken as CoursesModifications}
            />
         </MaterialModal>
      </AllCoursesContainer>
   );
};

export default AllCourses;