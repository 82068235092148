import jwt_decode from "jwt-decode";
import { UserDataType, UserOneProfileType, UserPaymentStatusType, UserStartedCourses, UserTransactionStatusType } from "../../types/user/user";
import { PROD_API } from "../../constants/api";
import { Dispatch } from "react";
import { COURSES } from "../../constants/courses";
import { getAllCourses, getPopularCourses, setCurrentCourse } from "./appActions";
import { LoadingStatuses } from "../reducers/types";
import { CoursesModifications } from "types/courses/courses";
import { TranslateType, changeTranslation } from "./translateAction";

export enum USER_ACTION_TYPES {
  SET_USER_DATA = "USER::SET_USER_DATA",
  SET_AUTH_TOKENS = "USER::SET_AUTH_TOKENS",
  SET_USER_INFO = "USER::SET_USER_INFO",
  SET_ALL_USERS = "USER::SET_ALL_USERS",
  SET_PAYMENT_STATUS = "USER::SET_PAYMENT_STATUS",
  SET_AUTH_ERROR = "USER::SET_AUTH_ERROR",
  SET_TRANZACTION_ID = "USER::SET_TRANZACTION_ID",
  SET_TRANSACTION_STATUS = "USER::SET_TRANSACTION_STATUS",
  SET_STARTED_COURSES_STATUS = "USER::SET_STARTED_COURSES_STATUS",
  SET_STARTED_COURSES = "USER::SET_STARTED_COURSES",
  SET_BOOKMARKED_COURSES_STATUS = "USER::SET_BOOKMARKED_COURSES_STATUS",
}

export type UserAction =
  | { type: USER_ACTION_TYPES.SET_USER_DATA; userData: UserDataType }
  | { type: USER_ACTION_TYPES.SET_AUTH_TOKENS; authTokens: { access: string; refresh: string } | null }
  | { type: USER_ACTION_TYPES.SET_USER_INFO; userProfile: UserOneProfileType }
  | { type: USER_ACTION_TYPES.SET_ALL_USERS; usersProfilesData: UserOneProfileType[] }
  | { type: USER_ACTION_TYPES.SET_AUTH_ERROR; authError: string | null }
  | { type: USER_ACTION_TYPES.SET_PAYMENT_STATUS; paymentStatus: UserPaymentStatusType }
  | { type: USER_ACTION_TYPES.SET_TRANZACTION_ID; id: string }
  | { type: USER_ACTION_TYPES.SET_TRANSACTION_STATUS; transactionStatus: UserTransactionStatusType }
  | { type: USER_ACTION_TYPES.SET_STARTED_COURSES_STATUS; startedCoursesStatus: LoadingStatuses }
  | { type: USER_ACTION_TYPES.SET_STARTED_COURSES; startedCourses: UserStartedCourses[] }
  | { type: USER_ACTION_TYPES.SET_BOOKMARKED_COURSES_STATUS; bookmarkedCoursesStatus: LoadingStatuses }

export const setUserData = ({ userData }: { userData: UserDataType }): UserAction => {
  return { type: USER_ACTION_TYPES.SET_USER_DATA, userData };
}
export const setAuthError = ({ authError }: { authError: string | null }): UserAction => {
  return { type: USER_ACTION_TYPES.SET_AUTH_ERROR, authError };
}

export const setAuthTokens = ({ authTokens }: { authTokens: { access: string; refresh: string } | null }): UserAction => {
  return { type: USER_ACTION_TYPES.SET_AUTH_TOKENS, authTokens };
}

export const setUserProfile = ({ userProfile }: { userProfile: UserOneProfileType }): UserAction => {
  return { type: USER_ACTION_TYPES.SET_USER_INFO, userProfile };
}

export const setStartedCourses = ({ startedCourses }: { startedCourses: UserStartedCourses[] }): UserAction => {
  return { type: USER_ACTION_TYPES.SET_STARTED_COURSES, startedCourses };
}

export const setAllUsersProfiles = ({ usersProfilesData }: { usersProfilesData: UserOneProfileType[] }): UserAction => {
  return { type: USER_ACTION_TYPES.SET_ALL_USERS, usersProfilesData };
}

export const setPaymentStatus = ({ paymentStatus }: { paymentStatus: UserPaymentStatusType }): UserAction => {
  return { type: USER_ACTION_TYPES.SET_PAYMENT_STATUS, paymentStatus };
}

export const setStartedCoursesStatus = ({ startedCoursesStatus }: { startedCoursesStatus: LoadingStatuses }): UserAction => {
  return { type: USER_ACTION_TYPES.SET_STARTED_COURSES_STATUS, startedCoursesStatus };
}

export const setBookmarkCoursesStatus = ({ bookmarkedCoursesStatus }: { bookmarkedCoursesStatus: LoadingStatuses }): UserAction => {
  return { type: USER_ACTION_TYPES.SET_BOOKMARKED_COURSES_STATUS, bookmarkedCoursesStatus };
}

export const setTransactionStatus = ({ transactionStatus }: { transactionStatus: UserTransactionStatusType }): UserAction => {
  return { type: USER_ACTION_TYPES.SET_TRANSACTION_STATUS, transactionStatus };
}

export const setTransactionId = ({ id }: { id: string }): UserAction => {
  return { type: USER_ACTION_TYPES.SET_TRANZACTION_ID, id };
}

export const loginUser = ({ username, password }: { username: string; password: string }) => {
  return async (dispatch: any) => {
    try {
      const response = await fetch(`${PROD_API}/api/users/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });
      const data = await response.json();
      dispatch(setUserData({ userData: jwt_decode(data.access) }));
      dispatch(setAuthTokens({ authTokens: data }));
      dispatch(changeTranslation({ newLanguage: data.language }))
      localStorage.setItem("authTokens", JSON.stringify(data));
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error.message)
      dispatch(setAuthError({ authError: 'Неверный email или login' }))
    }
  };
}

export const logoutUser = () => {
  return async (dispatch: any) => {
    try {
      localStorage.removeItem("authTokens");
      dispatch(setAuthTokens({ authTokens: null }));
      dispatch(setUserData({ userData: null }));
      dispatch(setUserProfile({ userProfile: null }));
      dispatch(setCurrentCourse({ courseModification: 'noSelected' }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
}

export const updateTokens = () => {
  return async (dispatch: any) => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/token/refresh`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh: authTokens.refresh,
        }),
      });
      const data = await response.json();
      dispatch(setUserData({ userData: jwt_decode(data.access) }));
      dispatch(setAuthTokens({ authTokens: data }));
      localStorage.setItem("authTokens", JSON.stringify(data));
    } catch (error) {
      logoutUser()
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
}

export const registerUser = ({
  first_name,
  last_name,
  password2,
  email,
  username,
  password
}: {
  first_name: string,
  password2: string,
  last_name: string;
  email: string,
  username: string;
  password: string;
}) => {
  return async (dispatch: any) => {
    try {
      const response = await fetch(`${PROD_API}/api/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
          email,
          first_name,
          last_name,
          password2,

        }),
      });
      const data = await response.json();

      // Check if the registration was successful
      if (response.ok) {
        // Create a profile for the registered user
        dispatch(loginUser({ username, password }))
      }

      dispatch(setUserData({ userData: jwt_decode(data.access) }));
      dispatch(setAuthTokens({ authTokens: data }));
      localStorage.setItem("authTokens", JSON.stringify(data));
    } catch (error: any) {
      // Handle registration error
      dispatch(setAuthError({ authError: 'Неверный email или login' }))
    }
  };
};


export const getUserInfo = () => {
  return async (dispatch: any) => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/info`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });
      const data = await response.json();

      if (response.status === 401 || data.code === "token_not_valid") {
        dispatch(logoutUser());
      }

      if (response.status === 200) {
        dispatch(setUserProfile({ userProfile: data }));
        dispatch(changeTranslation({ newLanguage: data.language }))
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
}

export const getAllUsers = () => {
  return async (dispatch: any) => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });
      const data = await response.json();

      if (response.status === 401 || data.code === "token_not_valid") {
        dispatch(logoutUser());
      }

      if (response.status === 200) {
        dispatch(setAllUsersProfiles({ usersProfilesData: data.users }));
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
}

export const updateUserPremiumCourses = ({ userId, premium_courses }: { userId: string; premium_courses: string[] }) => {
  return async (dispatch: any) => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/update_premium_courses`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({
          profile_id: userId,
          premium_courses,
        }),
      });

      if (response.status === 200) {
        dispatch(getAllUsers());
        dispatch(getUserInfo());
        dispatch(getAllCourses());
        dispatch(getPopularCourses())
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
}

export const updateUserBookmarkedCourses = ({ userId, bookmarked_courses }: { userId: string; bookmarked_courses: CoursesModifications[] }) => {
  return async (dispatch: any) => {
    try {
      dispatch(setBookmarkCoursesStatus({ bookmarkedCoursesStatus: 'loading' }))
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/update_bookmarked_courses`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({
          profile_id: userId,
          bookmarked_courses,
        }),
      });
      if (response.status === 200) {
        dispatch(getAllUsers());
        dispatch(getUserInfo());
        dispatch(setBookmarkCoursesStatus({ bookmarkedCoursesStatus: 'idle' }))
      }

      if (response.status !== 200) {
        dispatch(setBookmarkCoursesStatus({ bookmarkedCoursesStatus: 'error' }))
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
}

export const updateUserProfilePhoto = ({ photo }: { photo: any }) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const formData = new FormData();
      formData.append("photo", photo);

      const response = await fetch(`${PROD_API}/api/users/image`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: formData,
      });

      if (response.status === 200) {
        dispatch(getUserInfo());
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
};

export const updateUserProfileBio = ({
  first_name,
  last_name,
  email,
  callback,
  callbackError }: {
    first_name: string,
    last_name: string,
    email: string,
    callback: () => void,
    callbackError: () => void
  }) => {
  return async (dispatch: any) => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/bio`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({
          first_name,
          last_name,
          email,
        }),
      });

      if (response.status === 200) {
        dispatch(getUserInfo());
        callback()
      }

    } catch (error) {
      callbackError()
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
};

export const activateUserEmail = ({ successCallback, errorCallback }: { successCallback: () => void, errorCallback: () => void }) => {
  return async (dispatch: any) => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/activate_email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (response.status === 200) {
        successCallback()
        dispatch(getUserInfo())
      }

    } catch (error) {
      errorCallback()
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
};

export const updateUserPassword = ({
  old_password,
  new_password,
  callback,
  callbackError }: {
    old_password: string,
    new_password: string,
    callbackError: () => void,
    callback: () => void
  }) => {
  return async (dispatch: any) => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/change_password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({
          old_password,
          new_password,
        }),
      });

      if (response.status === 200) {
        dispatch(getUserInfo());
        callback()
      }

      if (response.status !== 200) {
        callbackError()
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
};

export const resetUserPassword = ({
  email,
  callback,
  callbackError }: {
    email: string,
    callbackError: () => void,
    callback: () => void
  }) => {
  return async (dispatch: any) => {
    try {

      const response = await fetch(`${PROD_API}/api/users/password_reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });

      if (response.status === 200) {
        dispatch(getUserInfo());
        callback()
      }

      if (response.status !== 200) {
        callbackError()
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
};

export const resetConfirmUserPassword = ({
  uidb64,
  token,
  password,
  callback,
  callbackError }: {
    uidb64?: string,
    token?: string,
    password: string,
    callbackError: () => void,
    callback: () => void
  }) => {
  return async (dispatch: any) => {
    try {
      const response = await fetch(`${PROD_API}/api/users/reset/${uidb64}/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
        }),
      });

      if (response.status === 200) {
        dispatch(getUserInfo());
        callback()
      }

      if (response.status !== 200) {
        callbackError()
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
};


export const getTransactionId = ({ amount, handleNextStep, enqueueSnackbar }: {
  amount: number, handleNextStep: () => void, enqueueSnackbar: (message: string, error: {
    variant: 'success' | 'error' | 'warning' | 'info' | 'default' | 'error' | 'success' | 'warning' | 'info' | 'default' | undefined
  }) => void
}) => {
  return async (dispatch: any) => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/get_transaction_id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({
          amount,
        }),
      });

      const data = await response.json();

      if (response.status === 200) {
        localStorage.setItem("transactionId", JSON.stringify(data.transaction_id));
        dispatch(setTransactionId({ id: data.transaction_id }));
        if (data.transaction_id === '' || data.transaction_id === undefined) {
          enqueueSnackbar("Ошибка при получении данных", { variant: 'error' })
          return;
        } else {
          window.open(`https://maib.ecommerce.md:443/ecomm01/ClientHandler?trans_id=${data.transaction_id}`, '_blank');
          handleNextStep();
          enqueueSnackbar("Данные об оплате отправлены", { variant: 'success' })
        }
      }

      if (response.status !== 200) {
        dispatch(setTransactionId({ id: '' }));
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
};

export const checkPaymentStatus = ({
  transaction_id,
  userId,
  premium_courses,
  course_to_update
}: {
  transaction_id: string,
  userId: string,
  premium_courses: string[],
  course_to_update: CoursesModifications
}) => {
  return async (dispatch: any) => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/verify_transaction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({
          transaction_id,
        }),
      });

      const data = await response.json();

      let updatedCourse = ''
      if (course_to_update === COURSES.restoreBroken) {
        updatedCourse = COURSES.restoreBrokenPremium
      } else if (course_to_update === COURSES.buckleUpProposal) {
        updatedCourse = COURSES.buckleUp
      } else if (course_to_update === COURSES.enterAltitudeProposal) {
        updatedCourse = COURSES.enterAltitude
      } else if (course_to_update === COURSES.moveUpwardsProposal) {
        updatedCourse = COURSES.moveUpwards
      } else if (course_to_update === COURSES.chooseDirectionProposal) {
        updatedCourse = COURSES.chooseDirection
      } else if (course_to_update === COURSES.maintainBalanceProposal) {
        updatedCourse = COURSES.moveUpwards
      }

      if (response.status === 200) {
        dispatch(setTransactionStatus({ transactionStatus: data.transaction_status }));
        if (data.transaction_status === 'OK') {
          const updated_courses = [...premium_courses, updatedCourse]
          dispatch(updateUserPremiumCourses({ userId, premium_courses: updated_courses }));
          dispatch(setPaymentStatus({ paymentStatus: 'payed' }));
          dispatch(sendPaymentEmail())
        }
        if (data.transaction_status === 'TIMEOUT' || data.transaction_status === 'FAILED' || data.transaction_status === 'DECLINED') {
          dispatch(setPaymentStatus({ paymentStatus: 'error' }));
        }
      }

      if (response.status !== 200) {
        dispatch(setTransactionStatus({ transactionStatus: 'ERROR' }));
        dispatch(setPaymentStatus({ paymentStatus: 'error' }));
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
};

export const sendPaymentEmail = () => {
  return async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      await fetch(`${PROD_API}/payment/success_email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({
          amount: 50
        }),
      });



    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
};


export const getUserStartedCourses = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setStartedCoursesStatus({ startedCoursesStatus: 'loading' }));
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/get_started_courses`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      const data = await response.json();

      if (response.status === 200) {
        dispatch(setStartedCourses({ startedCourses: data.started_courses }));
        dispatch(setStartedCoursesStatus({ startedCoursesStatus: 'idle' }));
      }

      if (response.status !== 200) {
        dispatch(setStartedCoursesStatus({ startedCoursesStatus: 'error' }));
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      dispatch(setStartedCoursesStatus({ startedCoursesStatus: 'error' }));
      console.warn(error);
    }
  };
};

export const updateUserStartedCourses = ({ courseModification, currentLesson }: {
  courseModification: CoursesModifications,
  currentLesson: number,
}) => {
  return async (dispatch: any) => {
    try {
      dispatch(setStartedCoursesStatus({ startedCoursesStatus: 'loading' }));
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/update_started_courses`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({
          course_modification: courseModification,
          current_lesson: currentLesson,
        }),
      });

      if (response.status === 200) {
        dispatch(getUserStartedCourses());
        dispatch(setStartedCoursesStatus({ startedCoursesStatus: 'idle' }));
      }

      if (response.status !== 200) {
        dispatch(setStartedCoursesStatus({ startedCoursesStatus: 'error' }));
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
};

export const updateTranslation = ({ newLanguage, i18n }: { newLanguage: TranslateType, i18n: any }) => {
  return async (dispatch: any) => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") as string);
      const response = await fetch(`${PROD_API}/api/users/language_change`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({
          language: newLanguage,
        }),
      });

      if (response.status === 200) {
        dispatch(getUserInfo());
        i18n.changeLanguage(newLanguage)
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
}